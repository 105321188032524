import {LandingImage} from "./landingImage";
import {About} from "./about";
import {Cognition} from "./cognition";
import {Project} from "./project";
import {Partners} from "./partner";
import {Contact} from "./contact";
import Layout from "../../compenents/layout";
import {useTranslation} from "react-i18next";
import {MdOutlineKeyboardArrowUp} from "react-icons/md";
import './home.css';

const Home = () => {
    const {t} = useTranslation();

    const handleClick = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    };
    return (
        <Layout>
            <LandingImage/>
            <About/>
            <div className="clearfix"></div>
            <Cognition/>
            <div className="clearfix"></div>
            <Project/>
            <div className="clearfix"></div>
            <Partners/>
            <div className="clearfix d-none"></div>
            <section className="section_blue">
                <h2 className="container">
                    {t('sectionBlue')}
                </h2>
            </section>
            <Contact/>
            <button id="moveToTop" onClick={handleClick} className="moveToTop">
                <MdOutlineKeyboardArrowUp />
            </button>
        </Layout>
    );
}

export default Home