import "./styles.css"
import {IMG_URL} from "../../utils/api/defaultApi";

const cercleIMG = [
    "cercle-jaune-338x338.webp",
    "cercle-violet-426x426.webp"
]

export const Subtitles = ({value, cercleID}) => {
    return (
        <div className="subtitles" >
            <p><img className="subtitles__puce" src={IMG_URL + cercleIMG[cercleID]} alt="sous-titre" /></p>
            <p>{value}</p>
        </div>
    );
}