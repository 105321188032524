import {Subtitles} from "../../../compenents/common";
import {IMG_URL} from "../../../utils/api/defaultApi";
import {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Container} from "../../../compenents/common/container";
import { css, StyleSheet } from 'aphrodite';
import {fadeIn} from "react-animations";
import {HashLink as Link} from "react-router-hash-link";

const styles = StyleSheet.create({
    tada: {
        animationName: fadeIn,
        animationDuration: '1s',
    },
});

const Origin = ({t}) => {
    return (
        <div className="origin">
            <div>
                <Subtitles value={t('about.origin.subtitle')} cercleID={0}/>
                <p>
                    <Trans components={{a: <a />}} >about.origin.p1</Trans>
                </p>
                <p>
                    <Trans components={{a: <a />}} >about.origin.p2</Trans>
                </p>
            </div>
            <figure>
                <img src={IMG_URL+'image'} alt="fond de dotation"/>
            </figure>
        </div>
    );
}

const DomaineComplementaire = ({t}) => {
    return (
        <div className="about_grid">
            <div>
                <Subtitles value={t('about.complementary.subtitle')} cercleID={0} />
                <p>{t('about.complementary.p1')}</p>
                <Trans components={{p: <p />, b: <b />}}>about.complementary.p2</Trans>
            </div>
            <p className="element_jaune">
                <img
                    src={IMG_URL + "element jaune 1.png"}
                    width="203.6px"
                    alt="fond de dotation"
                />
            </p>
        </div>
    );
}

const GeneseElement = ({el}) => {
    const [show, setShow] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setShow(!show)
    }
    return (
        <>
            <hr/>
            <a onClick={handleClick} href="/src/pages" className="title">
                {/*<p>*/}
                {/*    { !show ? <img src={IMG_URL + "/bouton triangle jaune/bouton-triangle-jaune-etat1.svg"} alt="puce-genese" width="15px"/> : null}*/}
                {/*    { show ? <img src={IMG_URL +  "/bouton triangle jaune/bouton-triangle-jaune-etat2.svg"} alt="puce-genese" width="15px"/> : null}*/}
                {/*</p>*/}
                <p><img src={IMG_URL + "/bouton triangle jaune/bouton-triangle-jaune-etat2.svg"} alt="puce-genese" width="15px"/></p>
                <p className="genese_date">{el.title}</p>
            </a>
            {/*{*/}
            {/*    !show ? null :*/}
            {/*        <div className={css(styles.tada)}>*/}
            {/*            <hr/>*/}
            {/*            {el.text}*/}
            {/*        </div>*/}
            {/*}*/}

                    <div>
                        <hr/>
                        {el.text}
                    </div>

        </>
    );
}

const GeneseSquare = ({t}) => {

    return (
        <div className="genese_square">
            <h3>{t('about.geneseSquare.h3')}</h3>
            <p>{t('about.geneseSquare.p1')}</p>
            <GeneseElement
                el={{
                    title: t("about.geneseSquare.element1.title"),
                    text: <Trans components={{p: <p />, a: <a />}}>about.geneseSquare.element1.p</Trans>
                }}
            />
            <GeneseElement
                el={{
                    title: t("about.geneseSquare.element2.title"),
                    text: <Trans components={{p: <p />, a: <a />}}>about.geneseSquare.element2.p</Trans>,
                }}
            />
            <GeneseElement
                el={{
                    title: t("about.geneseSquare.element3.title"),
                    text: <Trans components={{p: <p />, a: <a />}}>about.geneseSquare.element3.p</Trans>
                }}
            />
            <GeneseElement
                el={{
                    title: t("about.geneseSquare.element4.title"),
                    text: <Trans components={{p: <p />, a: <a />}}>about.geneseSquare.element4.p</Trans>
                }}
            />
            <GeneseElement
                el={{
                    title: t("about.geneseSquare.element5.title"),
                    text: <Trans components={{p: <p />, a: <a />}}>about.geneseSquare.element5.p</Trans>
                }}
            />
        </div>

    );
}

const AboutObject = ({t}) => {
    return (
        <div className="object">
            <div>
                <Subtitles value={t("about.object.subtitle")} cercleID={0} />
                <Trans components={{p: <p />, b: <b />, strong: <strong />}}>about.object.p</Trans>
                <Link to="#project">{t('about.object.link')}</Link>
            </div>
            <p className="diplo_02">
                <img
                    src={IMG_URL + 'photo-dlp02.webp'}
                    alt="diplo-2"
                />
            </p>
        </div>
    );
}

export const About = () => {
    const {t} = useTranslation();

    return (
        <section className="about">
            <Container>
                <h1 id="about" className="h1">{t('about.h1')}</h1>
                <hr/>
                <p className="introduction">
                    <Trans components={{ a: <a />}}>about.introduction</Trans>
                </p>
                <Origin t={t} />
                <div className="clearfix"></div>
                <DomaineComplementaire t={t} />
                <div className="clearfix"></div>
            </Container>
            <div className="genese">
                <p className='genese_p'><img src={IMG_URL + 'photo-dlp01.webp'} alt="diplo01" /></p>
                <GeneseSquare t={t} />
            </div>
            <div className="clearfix"></div>
            <AboutObject t={t} />
            <Container>
                <div className="clearfix"></div>
                <div className="tasks">
                    <p className="element_jaune"><img src={IMG_URL + "element-jaune-01.webp"} alt="element-jaune"/></p>
                    <div>
                        <Subtitles cercleID={0} value={t('about.task.subtitle')}/>
                        <Trans components={{p: <p />, b: <b />}}>about.task.text</Trans>
                    </div>
                </div>
            </Container>
        </section>
    );
}
