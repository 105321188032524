import {IMG_URL} from "../../../utils/api/defaultApi";
import {ButtonLink} from "../../../ui/button";
import {useTranslation} from "react-i18next";

export const LandingImage = () => {
    const {t} = useTranslation();

    return (
        <>
            <div className="landing">
                <div className="container">
                    <h1>
                        {t('landing.h1')}
                    </h1>
                    <p>
                        {t('landing.p')}
                    </p>
                    <ButtonLink href="/#contact">{t('landing.btn')}</ButtonLink>
                </div>
            </div>
            <p className="landing__paragraph_sm">
                {t('landing.p')}
            </p>
            <div className="cercle__yellow">
                <img src={IMG_URL + 'cercle-jaune-338x338.webp'} alt="cercle-jaune"/>
            </div>
        </>
    );
}
