import {IMG_URL} from "../../../utils/api/defaultApi";

export const Footer = () => {
    function handleClick(e) {
        e.preventDefault();
        alert("fonction bientôt disponible")
    }
  return (
      <footer>
          <div className="footer">
              <p><img src={IMG_URL+"logo-fdd-blanc.webp"} alt="fondDeDotationLogoBlanc" /></p>
              <div>
                  <p className="footer__address">10, Rue Saint-Maur <br /> 76000 Rouen</p>
                  <a href="/" onClick={handleClick}>Mentions légales</a>
              </div>
          </div>
      </footer>
  );
}