import Cookies from 'js-cookie';

const Authorized = [
    {email: 'malarbillaudrey@gmail.com', password: 'vegeta'}
]

export const Authenticate = (credentials) => {
    let userFound = null;
    Authorized.map(element => {
        if(element.email === credentials.username) {
            userFound = element;
        }
        return null;
    })
    if(userFound !== null) {
        if(userFound.password === credentials.password) {
            Cookies.set('JWT', 'Ca marche');
            const options = {status: 200, statusText: 'login success'}
            return new Response('login success',options);
        } else  {
            const options = {status: 400, statusText: 'password incorrect'}
            return new Response('login failed',options);
        }
    } else {
        const options = {status: 404, statusText: 'user notfound'}
        return new Response('login failed',options);
    }

}

export const logout = () => {
    Cookies.remove('JWT');
    const options = {status: 200, statusText: 'logout success'}
    return new Response('logout success',options);
}