import {Routes, Route} from 'react-router-dom';
import Home from "./pages/home/home";
import Admin from "./pages/admin/admin";
import Login from "./pages/admin/Login/login";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {

    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/admin" element={<Admin/>}/>
                <Route path="/admin/login" element={<Login/>}/>
            </Routes>
            <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
        </>
    );
};

export default App;
