import {IMG_URL} from "../../../utils/api/defaultApi";
import {useState} from "react";
import {HashLink as Link} from "react-router-hash-link";
import {RiMenu3Fill} from "react-icons/ri";
import {useTranslation} from "react-i18next";

const NavContent = () => {
    const {t, i18n} = useTranslation();

    const handleClick = (e) => {
        e.preventDefault();
        i18n.language === 'fr' ? i18n.changeLanguage("en") : i18n.changeLanguage("fr");
    }


    return (
        <div className="nav_content">
            <Link to="#about">
                <span></span>
                {t('nav.about')}
            </Link>
            <Link to="#cognition">
                <span></span>
                {t('nav.cognition')}
            </Link>
            <Link to="#project">
                <span></span>
                {t('nav.project')}
            </Link>
            <Link to="#partner">
                <span></span>
                {t('nav.partner')}
            </Link>
            <Link to="#contact">
               <button>
                   {t('nav.contact')}
               </button>
            </Link>
            <a onClick={handleClick}>
                <span></span>
                {t('nav.lang')}
            </a>
        </div>);
}

const NavContentSM = ({show, setShow}) => {
    const {t, i18n} = useTranslation();

    const handleClick = (e) => {
        e.preventDefault();
        i18n.language === 'fr' ? i18n.changeLanguage("en") : i18n.changeLanguage("fr");
        setShow(false);
    }

    return (
        show ?
            <div className="nav_content">
                <Link to="#about" onClick={()=>setShow(false)}>
                    <span></span>
                    {t('nav.about')}
                </Link>
                <Link to="#cognition" onClick={()=>setShow(false)}>
                    <span></span>
                    {t('nav.cognition')}
                </Link>
                <Link to="#project" onClick={()=>setShow(false)}>
                    <span></span>
                    {t('nav.project')}
                </Link>
                <Link to="#partner" onClick={()=>setShow(false)}>
                    <span></span>
                    {t('nav.partner')}
                </Link>
                <Link to="#contact" onClick={()=>setShow(false)}>
                    <button>
                        {t('nav.contact')}
                    </button>
                </Link>
                <a onClick={handleClick} >
                    <span></span>
                    {t('nav.lang')}
                </a>
            </div> : null);
}

const NavButton = ({screenWith, handler}) => {
    return screenWith < 1024 ? (
        <button onClick={handler}>
            <RiMenu3Fill className="menu_icon"/>
        </button>) : null;
}


export const Header = () => {
    const screenWidth = window.innerWidth;
    const [show, setShow] = useState(false);

    function handleClick(e) {
        e.preventDefault();
        setShow(!show);
    }


    return (
        <header>
            <div className="nav_header">
                <NavButton handler={handleClick} screenWith={screenWidth} />
                <Link to="/">
                    <img src={IMG_URL + "logo-fdd-174.webp"} alt="logo-fonds-de-dotation" />
                </Link>
            </div>
            {screenWidth >= 1024 ? <NavContent /> : <NavContentSM show={show} setShow={setShow} />}
        </header>

    );
}