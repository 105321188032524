import { useState } from "react";

import { Subtitles, Title } from "../../../compenents/common";
import { IMG_URL } from "../../../utils/api/defaultApi";
import { Container } from "../../../compenents/common/container";
import {Trans, useTranslation} from "react-i18next";
import {css, StyleSheet} from "aphrodite";
import {fadeIn} from "react-animations";

const styles = StyleSheet.create({
    tada: {
        animationName: fadeIn,
        animationDuration: '1s',
    },
});

const Processus = () => {
  return (
    <div className="processus">
      <div>
          <Trans components={{h3: <h3 />, p: <p />, a: <a />}}>cognition.processus</Trans>
      </div>
      <p className="element_violet">
        <img src={IMG_URL + "element-violet-01.webp"} alt="element-violet" />
      </p>
    </div>
  );
};

const DeveloppementCapaciteCognitive = () => {
  return (
    <div className="cognitiv_dev">
      <p className="left">
        <img src={IMG_URL + "photo-togo-01.webp"} alt="img-togo-01" />
      </p>
      <div>
        <Subtitles
          value="Développement des capacités cognitives"
          cercleID={1}
        />
        <div className="main_text">
          <Trans components={{p: <p />, b: <b />, br: <br />, a: <a />}} >cognition.develop</Trans>
        </div>
      </div>
    </div>
  );
};

const MaintienCapaciteCognitive = () => {
  return (
    <div className="cognition_main">
      <div>
        <Subtitles
          value="Maintien ou restauration des capacités cognitives"
          cercleID={1}
        />
        <div className="main_text">
         <Trans components={{p: <p />, b: <b />}}>cognition.maintain</Trans>
        </div>
      </div>
      <p className="element_violet">
        <img src={IMG_URL + "element-violet-02.webp"} alt="element-violet-02" />
      </p>
    </div>
  );
};

const DetailsDiv = () => {
  const {t} = useTranslation();
  const [isShow, setIsShow] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    setIsShow(!isShow);
  }

  return (
    <>
      {isShow ? (
        <a href="/src/pages" hidden={!isShow} onClick={(e) => handleClick(e)}>
          <img
            src="/images/Bouton%20triangle%20vert.svg"
            className="rotateY"
            alt="readmore"
          />
          <b>{t('cognition.zoom.hide')}</b>
        </a>
      ) : (
        <a href="/src/pages" hidden={isShow} onClick={(e) => handleClick(e)}>
          <img src="/images/Bouton%20triangle%20vert.svg" alt="readmore" />
          <b>{t('cognition.zoom.show')}</b>
        </a>
      )}
      <div className={"details " + css(styles.tada)} hidden={!isShow}>
          <Trans components={{div: <div />, p: <p />, sup: <sup />, small: <small />}}>cognition.zoom.details</Trans>
      </div>
    </>
  );
};

export const Cognition = () => {
  const {t} = useTranslation();

  return (
    <section className="cognition">
      <div className="clearfix"></div>
      <p className="section_image">
        <img src={IMG_URL + "Cercle violet.png"} alt="cercle violet coupé" />
      </p>
      <Container>
        <div className="clearfix"></div>
        <Title id="cognition" value={t('cognition.title')} />
        <div className="clearfix"></div>
        <Processus />
        <div className="clearfix"></div>
      </Container>
        <DeveloppementCapaciteCognitive />
        <div className="clearfix"></div>
        <MaintienCapaciteCognitive />
        <div className="clearfix"></div>

        <div className="header3">
          <p className="right">
            <img src={IMG_URL + "photo-togo-02.webp"} alt="togo-02" />
          </p>
          <h3>
            {t('cognition.header3')}
          </h3>
        </div>
        <Container>

        <p id="element_violet" className="element_violet">
          <img
            src={IMG_URL + "element-violet-03.webp"}
            className="element_violet3"
            alt="element-violet-03"
          />
        </p>
        <div className="clearfix"></div>
        <h1 className="container zoom_contain">{t('cognition.zoom.focus')}</h1>
        <div className="zoom">
          <p style={{textAlign: 'center'}}>
            <img src={IMG_URL + "schema1000jours.webp"} alt="schema1000jours" />
          </p>
          <h2>{t('cognition.zoom.h3')}</h2>
          <p className="zoom_introduction">
            {t('cognition.zoom.introduction')}
          </p>
          <DetailsDiv />
        </div>
      </Container>
    </section>
  );
};
