import {HashLink as Link} from "react-router-hash-link";

export const ButtonSubmit = (props) => {
    return (
        <button className="button" >
            {props.children}
        </button>
    );
}

export const ButtonLink = (props) => {

    return (
        <Link to={props.href} className="button">
            {props.children}
        </Link>
    );
}