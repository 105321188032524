import {Header} from "./header";
import {Footer} from "./footer";
import {Trans} from "react-i18next";
import {Helmet} from "react-helmet";

const Layout = (props) => {
    return (
        <>
            <Helmet>
                <Trans components={{title: <title />}}>title</Trans>
            </Helmet>
            <Header />
                {props.children}
            <Footer />
        </>
    );
}

export default Layout;