import { Title } from "../../../compenents/common";
import { IMG_URL } from "../../../utils/api/defaultApi";
import {useState} from "react";
import { ButtonSubmit } from "../../../ui/button";
import { Container } from "../../../compenents/common/container";
import {Trans, useTranslation} from "react-i18next";

const InputForm = (props) => {
  const { placeholder, name, value, handleChange } = props;

  return (
    <div>
      <input
        type="text"
        id={name}
        className="form-control"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

const ContactForm = ({t}) => {
  const [data, setData] = useState({
    name: "",
    phoneNumber: "",
    organization: "",
    email: "",
    message: "",
  });

  function handleChange(e) {
    console.log(e);
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log(data);
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div>
          <InputForm
            placeholder={t('contact.form.name')}
            name="name"
            handleChange={handleChange}
            value={data.name}
          />
          <InputForm
            placeholder={t('contact.form.organization')}
            name="organization"
            handleChange={handleChange}
            value={data.organization}
          />
        </div>
        <div>
          <InputForm
            placeholder={t('contact.form.phoneNumber')}
            name="phoneNumber"
            handleChange={handleChange}
            value={data.phoneNumber}
          />
          <InputForm
            placeholder={t('contact.form.email')}
            name="email"
            handleChange={handleChange}
            value={data.email}
          />
        </div>
        <div>
          <textarea
            id="message-form"
            className="form-control"
            rows="7"
            cols="30"
            placeholder="Message"
            name="message"
            value={data.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <p>
          <ButtonSubmit>{t('contact.form.submit')}</ButtonSubmit>
        </p>
      </form>
    </div>
  );
};

const ListElement = (props) => {
  const { imgUrl, name, title, subtitle, text } = props;
  return (
    <div>
      <p className="contact_logo">
        <img src={imgUrl} alt={name} width="80px" height="67px" />
      </p>
      <h4>{title}</h4>
      <b>{subtitle}</b>
      <p>{text}</p>
    </div>
  );
};

const ListSection = ({t}) => {
  return (
    <div className="container">
      <div className="info">
        <ListElement
          imgUrl={IMG_URL + "picto-FDD-mains.webp"}
          name="picto-main"
          title={t('contact.listSection.el1.title')}
          subtitle={t('contact.listSection.el1.subtitle')}
          text={t('contact.listSection.el1.text')}
        />
        <ListElement
          imgUrl={IMG_URL + "picto-FDD-livre.webp"}
          name="picto-livre"
          title={t('contact.listSection.el2.title')}
          subtitle={t('contact.listSection.el2.subtitle')}
          text={t('contact.listSection.el2.text')}
        />
        <ListElement
          imgUrl={IMG_URL + "picto-FDD-don.webp"}
          name="picto-don"
          title={t('contact.listSection.el3.title')}
          subtitle={t('contact.listSection.el3.subtitle')}
          text={t('contact.listSection.el3.text')}
        />
      </div>
    </div>
  );
};

export const Contact = () => {
  const {t} = useTranslation();

  return (
    <section className="contact">
      <p className="section_image">
        <img src={IMG_URL + "cercle-rose-443x443.webp"} alt="cercle rose" />
      </p>
      <Container>
        <div className="clearfix"></div>
        <Title id="contact" value={t('contact.title')} />
        <div className="clearfix"></div>
        <h4 className="container">
         <Trans components={{br: <br />}}>contact.subtitle</Trans>
        </h4>
        <ListSection t={t} />
        <div className="clearfix"></div>
        <div className="contact_square">
          <h2 className="container"> 100 %</h2>
          <div>
            <p>
             <Trans components={{b: <b />}}>contact.square.p1</Trans>
            </p>
            <p>
              <Trans components={{b: <b />}}>contact.square.p2</Trans>
            </p>
            <ul>
              <li>
                {" "}
                {t('contact.square.list.first')}
              </li>
              <li>
                {t('contact.square.list.second')}
              </li>
            </ul>
          </div>
        </div>
        <div className="clearfix-2"></div>
        <ContactForm t={t} />
      </Container>
    </section>
  );
};
