import {useState} from "react";
import {Authenticate} from "../../../utils/services/auth";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import './login.css';

const Login = () => {
    const [credentials, setCredentials] = useState({
        username: "",
        password: ""
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCredentials({...credentials, [name]: value})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const res = Authenticate(credentials);
        if(res.status === 200) {
            toast.success('vous avez été correctement connectés');
            navigate('/admin')
        } else {
            if(res.status === 404) {
                toast.error('utilisateur inconnu');
            } else if(res.status === 400) {
                toast.error('Mot de passe incorrect');
            } else {
                toast.error('Problèmes dans les identifiants ');
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} >
            <h3>Connectez-vous!</h3>
            <div>
                <label htmlFor="username">Email </label>
                <input type="text"  name="username" placeholder="votre email" onChange={handleChange} required />
            </div>

            <div>
                <label htmlFor="password">Mot de passe </label>
                <input  type="password" name="password" placeholder="votre email" onChange={handleChange} required />
            </div>
            <button type='submit'>Envoyer</button>
        </form>)
}

export default Login;