import {Title} from "../../../compenents/common";

import {IMG_URL} from "../../../utils/api/defaultApi";
import {Container} from "../../../compenents/common/container";
import {Trans, useTranslation} from "react-i18next";

const PartnerSection = ({title, img}) => {

    const handleClick = (e, link) => {
        if(link === "") {
            e.preventDefault();
        }
    }
    return (
            <div className="partner_div_section">
                <h4>{title}</h4>
                <div>
                    {img.map((element, index) =>
                        <p
                            key={index}
                            className="partner_logo"
                        >
                            <a
                                href={element.link}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e)=>handleClick(e, element.link)}
                            >
                                <img
                                    src={element.url}
                                    alt={element.alt}
                                />
                            </a>
                        </p>
                    )}
                </div>
            </div>
    );
}

const PartnerSectionList = () => {
    const {t} = useTranslation();

    return (
        <div className="partner_div">
            <PartnerSection
                title={t('partner.h4')}
                img={[
                    {url: IMG_URL+"university-west-indies.png", alt: "west indies", link: "https://www.uwi.edu/"},
                    {url: IMG_URL+"logo_isthia.webp", alt: "isthia", link: "https://www.isthia.fr/"},
                    {url: IMG_URL+"ianel.png", alt: "ianel", link: "https://www.africa-neuro-educ.org/"}
                ]}
            />
            <hr />
            <PartnerSection
                title={t('partner.partnerSection1')}
                img={[
                    {url: IMG_URL+"logo-CFF.webp", alt: "CFF", link: "https://www.centre-francais-fondations.org/"},
                    {url: IMG_URL+"logo-ana-nour.webp", alt: "anar-hour", link: "https://www.ana-nour.org/"}
                ]}
            />
            <hr />
            <PartnerSection
                title={t('partner.partnerSection2')}
                img={[
                    {url: IMG_URL+"logo-groupe-nutriset.webp", alt: "nutriset", link: "https://www.nutriset.fr/"},
                    {url: IMG_URL+"logo-zz.webp", alt: "zz", link: "https://www.agencezigzag.fr/"},
                    {url: IMG_URL+"logo-dlp.webp", alt: "dlp", link: ""}
                ]}
            />
        </div>
    );
}

export const Partners = () => {
    const {t} = useTranslation();

     return (
         <section className="partner">
             <div className="clearfix"></div>
             <p className="section_image">
                 <img src={IMG_URL+"Cercle bleu.png"} alt='cercle-bleu'/>
             </p>
             <Container>
                 <div className="clearfix"></div>
                 <Title id="partner"  value={t('project.title')} />
                 <div className="clearfix"></div>
                 <Trans components={{h3: <h3></h3>, br: <br />}}>partner.h3</Trans>
                 <div className="clearfix-1"></div>
                 <PartnerSectionList />
             </Container>
         </section>
     );
}
