import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './texts/translationEN.json'
import translationFR from './texts/translationFR.json'

const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            fr: {
                translation: {
                    nav: {
                        about: "Qui sommes-nous",
                        cognition: "Cognition et Nutrition",
                        project: "Projets",
                        partner: "Partenaires",
                        contact: "Contactez-nous",
                        lang: "ENG"
                    },
                    landing: {
                        h1: "Améliorer l’accès à la santé et à l’éducation pour tous",
                        p: "Face à la multiplicité des causes et l’étendue des savoirs encore à acquérir en nutrition, le Groupe Nutriset a souhaité mettre en place un fonds de dotation, nouvel outil qui permet de soutenir toujours plus de recherche, de multiplier les types de partenariats, générer et partager de nouvelles connaissances.",
                        btn: "Devenir partenaire"
                    },
                    about: {
                        h1: "Qui est le Fonds de dotation Groupe Nutriset ?",
                        introduction: "En février 2021, <a href=\"https://www.nutriset.fr/\" target=\"_blank\" className=\"nutriset\" rel=\"noreferrer\">le Groupe Nutriset</a> a voulu donner une autre dimension à son  action, par la création d’un fonds de dotation.",
                        origin: {
                            subtitle: "À l’origine, Nutriset",
                            p1: "En 1986, Michel Lescanne crée <a href=\"https://www.nutriset.fr/\" target=\"_blank\" rel=\"noreferrer\" className=\"nutriset\">Nutriset</a> sur un constat : des millions d’enfants meurent chaque année de malnutrition dans les pays pauvres, et la communauté internationale demeure alors dans l'incapacité de pouvoir les soigner, faute de traitements adaptés.",
                            p2: " <a href=\"https://www.nutriset.fr/\" target=\"_blank\" rel=\"noreferrer\">Le Groupe Nutriset</a> se spécialise dans la recherche, le développement et la production d’aliments à haute valeur nutritionnelle, destinés à traiter et prévenir les différentes formes de malnutrition dans le monde. Le groupe développe des systèmes de production pérennes et responsables au plus proche des besoins."
                        },
                        complementary: {
                            subtitle: "Explorer des domaines complémentaires",
                            p1: " L’activité du Groupe s’est toujours appuyée sur des expertises et des collaborations scientifiques variées, utilisant ses ressources et compétences propres ou s'associant avec des partenaires de recherche externes (nutrition, sciences sociales, science des aliments).",
                            p2: "<p>En février 2021, afin d’explorer des domaines complémentaires à son mandat initial, <b>le Groupe Nutriset a donné une nouvelle dimension à son action avec la création d’une structure d’intérêt général : le Fonds de dotation Groupe Nutriset.</b></p>"
                        },
                        geneseSquare: {
                            h3: "Genèse du Fonds de dotation Groupe Nutriset",
                            p1: "Évolution croisée de l’humanitaire, de la recherche et du Groupe Nutriset depuis sa création.",
                            element1: {
                                title: "Les années 1980",
                                p: "<p>- La faim dans le monde est un thème récurrent dans l’opinion publique. Des formules de lait enrichies sont testées sur des crises humanitaires.</p>" +
                                    "<p>- Le 1er mai 1986, Michel Lescanne fonde <a href=\"https://www.nutriset.fr/\" target=\"_blank\" rel=\"noreferrer\" className=\"nutriset\">Nutriset</a>.</p>"
                            },
                            element2: {
                                title: "Les années 1990",
                                p: "<p>- En 1992, la FAO et l’OMS convoquent la première Conférence Internationale sur la Nutrition.</p>\n" +
                                    "<p>- En 1994, le génocide rwandais pose la question du traitement efficace et à grande échelle des enfants victimes de malnutrition aigüe sévère...</p>\n" +
                                    "<p>- Nutriset propose Plumpy’Nut®, un aliment thérapeutique prêt à l’emploi facilement conservable et distribuable pour une utilisation communautaire.</p>\n"
                            },
                            element3: {
                                title: "Les années 2000",
                                p: "<p>- Face à l’explosion des besoins, un continuum de l’aide s’impose de l’urgence au développement. La nutrition devient un thème d’intervention en soi...</p>\n" +
                                    "<p>- De la notion de traitement, on passe à celle de prévention et Nutriset développe en collaboration avec l’université américaine UC Davis Nutributter.</p>"
                            },
                            element4: {
                                title: "Les années 2010",
                                p: "<p> - Les ONG humanitaires deviennent des acteurs majeurs de la gestion des crises humanitaires. Sous l’égide de l’OMS qui établit les normes d’alimentation des femmes enceintes et allaitantes, des nourrissons et des enfants de 6 à 24 mois, la période des 1000 jours de la conception de l’enfant à ses deux ans focalise l’attention des nutritionnistes. Nutriset développe à cette fin Enov’Mum et Plumpy’Mum à l’attention des femmes enceintes et allaitantes. </p>"
                            },
                            element5: {
                                title: "Depuis 2020",
                                p: "<p> - The Lancet publie l’article ‘Effective interventions to address maternal and child malnutrition : an update of the evidence’ et met en lumière l’impact positif des SQ-LNS. Cette recommandation est un gage de reconnaissance de l’action du Groupe Nutriset depuis 35 ans auprès des populations les plus vulnérables pour leur permettre d’être en bonne santé et de mieux vivre par la nutrition.</p>\n" +
                                    "<p>- Le Fonds de dotation Groupe Nutriset est créé en 2021 pour aller plus loin dans cette mission et soutenir des recherches et des actions d’intérêt général en faveur d’une meilleure santé globale et éducation de ces populations.</p>"
                            }
                        },
                        object: {
                            subtitle: "L’objet du Fonds de dotation Groupe Nutriset",
                            p: "<p>Le Fonds de dotation Groupe Nutriset met en œuvre directement, ou indirectement par le biais d’autres organismes sans but lucratif:</p>\n" +
                                "<p>- Des actions en vue de <b>l’accès du plus grand nombre à un bon état de santé et à l’éducation,</b></p>" +
                                "<p>- Des activités de recherche en nutrition, sciences des aliments et sciences humaines et toute autre thématique de <b>recherche en lien avec la santé et l’éducation.</b> </p>\n" +
                                " <p>En vue de répondre notamment aux besoins fondamentaux des populations en matière d’éducation et de santé, il organise <strong>des projets humanitaires</strong> ou finance des organismes éligibles au régime du  mécénat pour mener ces actions. </p>",
                            link: "Voir les projets soutenus"
                        },
                        task: {
                            subtitle: "Les activités du Fonds de dotation Groupe Nutriset",
                            text: "<p> De manière directe ou par le biais de partenaires, ses missions consistent à soutenir et organiser des actions dans les domaines de la recherche, l’éducation et l’humanitaire :</p>\n" +
                                "<p><b>Organiser des actions de recherche et de formation</b> (soutien d’études de terrain, thèses, publications scientifiques), des rencontres, des ateliers, des colloques et des séminaires ; </p>\n" +
                                "<p><b>Mettre en place des partenariats</b> avec des associations et fondations dans le domaine de la santé et l’éducation.</p>\n" +
                                "<p><b>En 2021, le Fonds de dotation Groupe Nutriset a identifié la cognition comme fil conducteur de ses activités initiales.</b>\n" +
                                "</p>\n" +
                                "<p>A l’issue de ce premier cycle, une autre thématique, dont les caractéristiques répondront à l’objet initialement défini, pourra devenir un nouveau fil rouge. </p>"
                        }
                    },
                    cognition: {
                        title: "La Cognition",
                        processus: "<h3>Cognition : Processus par lequel un organisme acquiert la consciencedes événements et objets de son environnement. (Le Robert)</h3>\n" +
                            "<p>Le Fonds de dotation Groupe Nutriset voit dans l’exploration du champ de la cognition et de ses interactions avec l’alimentation et la nutrition, la possibilité de soutenir un vaste ensemble pluridisciplinaire de recherches et d’actions. Sa vocation d’améliorer l’accès du plus grand nombre à un bon état de santé, est un écho au mandat opérationnel des structures du groupe. </p>",

                        develop: "<p>Les spécialistes de la petite enfance ont démontré que,  <b>pour se développer harmonieusement</b> et avoir la capacité d’ <b>atteindre son plein potentiel</b>, un enfant a besoin de recevoir une alimentation adaptée à ses besoins, et bénéficier d’affection, de protection et de stimulation. (<a href='https://www.unicef.org/fr/developpement-de-la-petite-enfance' target='_blank' rel='noreferrer' >Unicef 2019</a>) </p>\n" +
                            "<p>Un enfant souffrant de malnutrition (dénutrition ou obésité), et peu stimulé sur le plan cognitif et socio-émotionnel, voit son développement affecté, de manière irréversible. C’est le cas de près de 43% des enfants de moins de cinq ans dénutris vivant dans les pays à revenu faible ou intermédiaire, qui ne reçoivent pas la nutrition, la protection et la stimulation dont ils ont besoin (<a href='https://www.unicef.org/fr/developpement-de-la-petite-enfance' target='_blank' rel='noreferrer' >Unicef 2019</a>)</p>\n" +
                            "<p><b>La stimulation cognitive, psychosociale et affective des enfants souffrant de malnutrition doit être complémentaire à une prise en charge nutritionnelle</b> pour favoriser la guérison et permettre de réduire les retards développementaux occasionnés. </p>" +
                            "<p><b>Pour les années 2022-24, le Fonds de dotation Groupe Nutriset soutient l’ONG française Ana-Nour pour accompagner la neuroéducation en Afrique. </b></p>",

                        maintain: "<p>Un retard de croissance physique ou de développement cognitif peut être considéré à la fois comme la conséquence d’une privation passée et comme un indicateur de pauvreté future. </p>" +
                            "<p><b>La dénutrition perturbe le développement normal du cerveau</b>, engendrant des dysfonctionnements étendus des fonctions cognitives, psychologiques et comportementales. Cela peut se traduire par des résultats scolaires suboptimaux, une performance économique réduite à l’âge adulte, ainsi que des troubles affectifs et sociaux. </p>" +
                            "<p><b>Les dysfonctionnements des fonctions exécutives</b> (capacité de concentration sur un objectif, mémoire de travail, régulation effective des émotions), aboutissent parfois à un déclin cognitif important chez les seniors (ex. cas de la maladie d’Alzheimer) et pourraient être également à l’origine des défauts de performance sociale ou économique des survivants de la malnutrition aiguë sévère. </p>" +
                            "<p className=\"important\"><b>Pour les années 2022 - 24 le Fonds de dotation Groupe Nutriset soutient le projet Ex-Core porté par le Professeur Terrence Forrester en Jamaïque. </b></p>",

                        header3: "Le Fonds de dotation Groupe Nutriset voit dans l’exploration du champ de la cognition et de ses interactions avec la nutrition, la possibilité de soutenir un vaste ensemble pluridisciplinaire de recherches et d’actions.",

                        zoom: {
                            focus: "ZOOM",
                            h3: "Les premiers 1000 jours",
                            introduction: "Les 1000 premiers jours de l’enfant – de sa conception à ses 2 ans – constituent un concept incontournable pour de nombreux scientifiques, qui soulignent l’importance de cette période clé pour tout individu.",
                            hide: "Cacher la suite",
                            show: "Lire la suite",
                            details: "<div>" +
                                "<p>Cette période, sensible pour le développement et la sécurisation de l’enfant, définit les prémisses de la santé et du bien-être<small><sup>1</sup></small> de l’individu tout au long de la vie. Il s’agit de proposer des recommandations pour mieux accompagner les parents durant cette phase déterminante pour leur enfant. </p>" +
                                "<p>Jouer, chanter, raconter des histoires, explorer la nature, favoriser l’éveil culturel et artistique, sont essentiels au développement de l’enfant. Ces activités immatérielles et de sociabilisation soutiennent l’attention du bébé et l’aident à repérer les contextes, les personnes et les récurrences utiles (comme celles du langage ou des lois naturelles). </p></div>" +
                                "<p> (Extrait du Rapport de la commission des 1000 premiers jours, commission d’expert présidée par Boris Cyrulnik, Ministère des Solidarités et de la Santé, Septembre 2020)</p>" +
                                "<p><sup>1</sup> Définition de la santé au sens de l’OMS : « La santé est un état de complet bien-être physique, mental et social et ne consiste pas seulement en une absence de maladie ou d’infirmité. » </p>"
                        }
                    },
                    project: {
                        title: "Les projets du Fonds de dotation Groupe Nutriset"
                    },
                    partner: {
                        title: "Nos partenaires et réseaux",
                        h3: "<h3 className=\"container partner_intro\">Ils soutiennent notre initiative et nous inspirent. <br /> Et si c’était vous demain ? </h3>",
                        h4: "Nos partenaires de recherche",
                        partnerSection1: "Fondations & ONG",
                        partnerSection2: "Mécènes et sponsors"
                    },
                    sectionBlue: "Face à l’étendue des savoirs encore à acquérir en nutrition, le Groupe Nutriset a souhaité mettre en place un fonds de dotation, pour permettre de financer toujours plus de recherche, de multiplier les types de partenariats, générer et partager de nouvelles connaissances.",
                    contact: {
                        title: "Nous contacter",
                        subtitle: "Vous impliquer aux côtés du Fonds de dotation <br />\n" +
                            "          Groupe Nutriset:",
                        listSection: {
                            el1: {
                                title: "Participer à des projets d’intérêt général",
                                subtitle: "Vous souhaitez faire avancer la recherche ?",
                                text: "Améliorons ensemble les connaissances académiques et scientifiques autour de la cognition et de ses interactions avec l’alimentation et la nutrition."
                            },
                            el2: {
                                title: "Faire valoir des valeurs humaines de partage et d’entraide",
                                subtitle: "Vous souhaitez nous soumettre un projet ?",
                                text: "Agissez concrètement et au plus proche des besoins en nous proposant vos projets opérationnels, dont la mission d’intérêt général a vocation à favoriser l’accès à une meilleure santé et à l’éducation.\n" +
                                    "                            Des appels à projet seront publiés sur ce site."
                            },
                            el3: {
                                title: "Soutenir la santé et l’éducation pour tous",
                                subtitle: "Vous souhaitez participer financièrement à nos activités ?",
                                text: "Aidez-nous à rassembler des fonds au profit de la recherche et des actions de terrain promouvant une meilleure santé et une meilleure éducation pour tous."
                            }
                        },
                        square: {
                            p1: "<b>de vos dons bénéficient directement aux projets</b>, le Groupe\n" +
                                "              Nutriset prenant en charge l’ensemble des frais de fonctionnement\n" +
                                "              du Fonds de dotation.",
                            p2: "<b>100 % des dons sont éligibles aux déductions fiscales:</b>",
                            list: {
                                first: "  - À hauteur de 60 % pour les entreprises payant l’impôt sur les\n" +
                                    "                sociétés.",
                                second: " - À hauteur de 66 % pour les particuliers, à déduire de leur\n" +
                                    "                impôt sur le revenu."
                            }

                        },
                        form: {
                            name: 'Nom',
                            organization: 'Organisation',
                            phoneNumber: "Numero de telephone",
                            email: "Adresse mail",
                            submit: "Envoyer"
                        }
                    }
                }
            },
            en: {
                translation: {
                    nav: {
                        about: "About us",
                        cognition: "Cognition and Nutrition",
                        project: "Projects",
                        partner: "Partners",
                        contact: "Contact us",
                        lang: "FR"
                    },
                    landing: {
                        h1: "Improving access to health and education for all",
                        p: "Faced with the multiplicity of causes and the scope of knowledge that still needs to be acquired in the field of nutrition, the Nutriset Group wanted to set up this new tool, a endowment fund, to allow us to finance more and more research, to multiply the partnerships and to generate and share new knowledge.",
                        btn: "Become Partner"
                    },
                    about: {
                        h1: "Who is Nutriset Group Endowment Fund ?",
                        introduction: " In February 2021, the <a href=\"https://www.nutriset.fr/en\" target=\"_blank\" className=\"nutriset\" rel=\"noreferrer\">Nutriset Group</a> decided to give another dimension to its action, by creating an Endowment Fund.",
                        origin: {
                            subtitle: "Initially, Nutriset",
                            p1: "In 1986, Michel Lescanne created <a href=\"https://www.nutriset.fr/\" target=\"_blank\" rel=\"noreferrer\" className=\"nutriset\">Nutriset</a> based on the observation that millions of children die every year from malnutrition in poor countries, and that the international community remains unable to treat them due to a lack of appropriate treatments.",
                            p2: "The Nutriset group specialises in the research, development and production of foods with high nutritional value, designed to treat and prevent the various forms of malnutrition in the world. The group develops sustainable and responsible production systems that meet needs as closely as possible."
                        },
                        complementary: {
                            subtitle: "Explore complementary areas",
                            p1: "The Group's activity has always been based on scientific expertise and various collaborations, using its own resources and skills or in association with external research partners (nutrition, social sciences, food science).",
                            p2: "<p>In February 2021, with the aim of exploring areas complementary to its initial mandate, <b>the Nutriset Group gave a new dimension to its action with the creation of a structure of public interest: the Nutriset Group Endowment Fund.</b></p>"
                        },
                        geneseSquare: {
                            h3: "The origins of the Endowment Fund",
                            p1: "The cross evolution of humanitarian aid, research and the Nutriset Group since its creation.",
                            element1: {
                                title: "1980s",
                                p: "<p>- World hunger is a recurrent issue in public opinion. Fortified milk formulas are tested in humanitarian crises. </p>\n" +
                                    "                            <p>- On May 1st, 1986, Michel Lescanne founded Nutriset.</p>"
                            },
                            element2: {
                                title: "The 1990s",
                                p: "<p>- In 1992, the FAO and WHO convene the first International Conference on Nutrition. </p>\n" +
                                    "                            <p>- In 1994, the Rwandan genocide raised the issue of effective, large-scale treatment of children suffering from severe acute malnutrition. </p>\n" +
                                    "                            <p>- Nutriset proposed Plumpy'Nut®, a ready-to-use therapeutic food that was easy to store and distribute for community use.</p>"
                            },
                            element3: {
                                title: "The 2000s",
                                p: "<p>-Faced with the explosion of needs, a continuum of aid is required from emergency to development. Nutrition becomes an intervention topic on its own. </p>\n" +
                                    "                            <p>- From the notion of treatment, we move to that of prevention and Nutriset develops Nutributter in collaboration with the American university UC Davis.</p>"
                            },
                            element4: {
                                title: "The 2010s",
                                p: "<p>- Humanitarian NGOs become major players in the management of humanitarian crises. Under the aegis of the WHO, which establishes nutrition standards for pregnant and breastfeeding women, infants and children from 6 to 24 months, the 1,000-day period from conception to the child's second birthday focuses the attention of nutritionists. Nutriset is developing Enov'Mum and Plumpy'Mum for pregnant and breastfeeding women.</p>"
                            },
                            element5: {
                                title: "Since 2020",
                                p: " <p>\n" +
                                    "                                - The Lancet published the article “Effective interventions to address maternal and child malnutrition: an update of the evidence” and highlighted the positive impact of QS-NS. This recommendation is a token of recognition of the Nutriset Group's 35 years of work with the most vulnerable populations to enable them to be healthy and live better through nutrition.\n" +
                                    "                            </p>\n" +
                                    "                            <p>\n" +
                                    "                                - The Nutriset Group endowment fund will be created in 2021 to carry this mission further and support research and actions of general interest in favor of better overall health and education for these populations.\n" +
                                    "                            </p>"
                            }
                        },
                        object: {
                            subtitle: "The purpose of the Endowment Fund ",
                            p: "<p>The endowment fund implements directly, or indirectly through other non-profit organisations of public interest:</p>\n" +
                                "<p>- Actions for the <b>access of the greatest number to a good state of health and to education,</b></p>\n" +
                                "<p>- Research activities in the field of <b>science, nutrition, food science and human sciences</b> and any other research topic related to health and education.</p>\n" +
                                "<p>With the aim of meeting the fundamental needs of populations in terms of education and health, it organises humanitarian actions or finances organisations eligible for sponsorship to carry out these actions.</p>",
                            link: "Our Projects"
                        },
                        task: {
                            subtitle: "The activities of the Endowment Fund",
                            text: "<p>Directly or through partners, its missions are to <b>support and organise actions in the fields of research, education and humanitarian aid:</b></p>\n" +
                                "<p>- Organise research and training activities (support for case studies, theses, scientific publications), meetings, workshops, conferences and seminars;</p>\n" +
                                "<p>- To set up partnerships with associations and foundations in the health and education spheres.</p>\n" +
                                "<p><b>In 2021, the Nutriset Group Endowment Fund has identified cognition as the common thread of its initial activities.</b></p>\n" +
                                "<p>At the end of this first cycle, another theme, with characteristics that correspond to the initially defined object, may become a new common thread.</p>"
                        }
                    },
                    cognition: {
                        title: "Cognition",
                        processus: "<h3>Cognition: The process by which an organism acquires awareness of events and objects in its environment (French dictionary LeRobert)</h3>\n" +
                            "<p>The Nutriset Group Endowment Fund sees in the exploration of the field of cognition and its interactions with food and nutrition, the possibility of supporting a wide range of multidisciplinary research and actions. Its vocation to improve access to good health for as many people as possible echoes the operational mandate of the group's structures.</p>",

                        develop: "<p> Early childhood experts have shown that, to grow harmoniously and have the capacity to reach its full potential, a child needs to be provided with adequate nutrition, affection, protection and stimulation.</p>" +
                            "<p>A child suffering from malnutrition (undernutrition or obesity), with little cognitive and socio-emotional stimulation, will see its development irreversibly affected. This is the case for nearly 43% of undernourished children under five in low- and middle-income countries, who do not receive the nutrition, protection and stimulation they need. <br /> <br />\n" +
                            "Cognitive, psychosocial and emotional stimulation of malnourished children must be complementary to nutritional care to promote recovery and reduce the developmental delays.</p>\n" +
                            "<p><b>For the years 2022-24, the Nutriset Group Endowment Fund supports the NGO Ana-Nour to promote neuro-education in Africa.</b></p>",

                        maintain: "<p>A delay in physical growth or cognitive development can be seen both as a consequence of past deprivation and as an indicator of future poverty.</p>\n" +
                            "<p>Undernutrition disrupts normal brain development, leading to widespread dysfunction in cognitive, psychological and behavioural functions. This can result in suboptimal school performance, reduced economic performance in adulthood, and emotional and social disorders.</p>\n" +
                            "<p>Dysfunctions in executive functions (ability to focus on a goal, working memory, effective regulation of emotions), sometimes lead to significant cognitive decline in older people (e.g. Alzheimer's disease) and could also be the cause of poor social or economic performance in survivors of severe acute malnutrition.</p>\n" +
                            "<p className=\"important\"><b>For the years 2022-24, the Nutriset Group Endowment Fund supports the Ex-Core project led by Professor Terrence Forrester in Jamaica.</b></p>",

                        header3: "The Nutriset Group Endowment Fund sees in the exploration of the field of cognition and its interactions with nutrition, the possibility of supporting a wide range of multidisciplinary research and actions.",

                        zoom: {
                            focus: "Focus on",
                            h3: "The 1000 days",
                            introduction: "The first 1,000 days of a child's life - from conception to age 2 - are a key concept for many scientists, who emphasise the importance of this key period for any individual",
                            hide: "Hide",
                            show: "Show",
                            details: "<div>" +
                                "<p>This sensitive period for child development and protection sets the stage for lifelong health and well-being¹. The aim is to propose recommendations to better support parents during this decisive phase for their child.</p>" +
                                "<p>Playing, singing, telling stories, exploring nature, encouraging cultural and artistic stimulation, are essential to the development of the child. These non-material and socialising activities support the baby's attention and help him or her to identify contexts, people and useful recurrences (such as those of language or natural laws).</p></div>" +
                                "<p>(Extract from the Report of the Commission of the first 1000 days, expert commission chaired by Boris Cyrulnik, Ministry of Solidarity and Health, September 2020)</p>"
                        }
                    },
                    project: {
                        title: "Nutriset Group Endowment Fund projects"
                    },
                    partner: {
                        title: "Our partners and network",
                        h3: "<h3 className=\"container partner_intro\">They support our initiative and inspire us. <br /> What if it was you tomorrow?</h3>",
                        h4: "Our research partners",
                        partnerSection1: "Foundations & NGO",
                        partnerSection2: "Patrons and sponsors"
                    },
                    sectionBlue: "Faced with the multiplicity of causes and the extent of knowledge still to be acquired in the field of nutrition, the Nutriset Group wished to set up this new tool, the endowment fund, to enable it to finance more and more research, multiply the types of partnerships and generate and share new knowledge.",
                    contact: {
                        title: "Contact us",
                        subtitle: "Getting involved with the Endowment Fund  <br />\n" +
                            "         Nutriset Group means...",
                        listSection: {
                            el1: {
                                title: "Participate in projects of public interest",
                                subtitle: "You want to advance research?",
                                text: "Let's work together to improve academic and scientific knowledge about cognition and its interaction with food and nutrition."
                            },
                            el2: {
                                title: "Promote human values of sharing and caring",
                                subtitle: "You want to submit a project to us? ",
                                text: "Make a concrete contribution by suggesting your operational projects, whose mission of general interest is to promote access to better health and education. Calls for projects will be published on this site"
                            },
                            el3: {
                                title: "Support health and education for all.",
                                subtitle: "You want to participate financially in our activities? ",
                                text: "Help us raise funds for research and operational projects promoting better health and education for all."
                            }
                        },
                        square: {
                            p1: "<b>of your donations go directly to the projects</b>,  as the Nutriset Group covers all the operating costs of the Endowment Fund.",
                            p2: "<b>100% of donations are eligible for tax deductions: </b>",
                            list: {
                                first: "- Up to 60% for companies paying corporate tax.",
                                second: "- Up to 66% for individuals, to be deducted from their income tax."
                            }
                        },
                        form: {
                            name: 'Name',
                            organization: 'Organization',
                            phoneNumber: "Phone number",
                            email: "Email",
                            submit: "Submit"
                        }
                    }
                }
            }
        }
    });

export default i18n;