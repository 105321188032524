import {useEffect, useState} from "react";
import Cookies from 'js-cookie';
import {useNavigate} from "react-router-dom";


const Admin = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        if(!Cookies.get('JWT')) {
            navigate('/admin/login');
        }
    }, [])

    return <div>ADMIN</div>
}

export default Admin;