import { Title } from "../../../compenents/common";

import { IMG_URL } from "../../../utils/api/defaultApi";
import { useState } from "react";
import { Container } from "../../../compenents/common/container";
import {useTranslation} from "react-i18next";
import {css, StyleSheet} from "aphrodite";
import {fadeIn} from "react-animations";

const styles = StyleSheet.create({
  tada: {
    animationName: fadeIn,
    animationDuration: '2s',
  },
});

const ProjectList = [
  {
    id: 0,
    title: "EXPLORER - Education au goût",
    text: "Comment se construisent les préférences alimentaires chez nos enfants ? Le goût est-il un sens inné ou est-il possible de le développer, voire même de l’éduquer ? Quel impact sur la santé de l'enfant ?",
    imgURL: "/images/temp/projets-explorer-01.webp",
    details: <div>
        <p>
          En mars 2022, une étude est lancée afin d’évaluer le lien entre
          alimentation et cognition, se focalisant particulièrement sur les
          déterminants de l’acquisition du goût. Le sujet porté par Aurore
          Pointet, étudiante à l’université de Toulouse – Jean Jaurès en
          sociologie de l’alimentation, est un regard croisé entre neurosciences
          et éducation au goût.{" "}
          <b>
            L’objectif est d’utiliser la cognition comme levier d’amélioration
            de la qualité de l’alimentation chez l’enfant. Le projet de
            recherche exploratoire se découpe en 3 phases :
          </b>
        </p>
        <ul>
          <li>
            <p>
              Revue de littérature et état des lieux de la recherche en
              neurosciences, nutrition et socio-anthropologie de l’alimentation
              concernant l’éducation au goût.
            </p>
          </li>
          <li>
            Screening des acteurs de la recherche autour du lien
            cognition/nutrition dans toutes les disciplines : neurosciences,
            sciences de l’éducation, sciences de l’alimentation,
            socio-anthropologie de l’alimentation…
          </li>
          <li>
            Réalisation d’entretiens avec des acteurs clés de la recherche et de
            l’opérationnel sur l’éducation au goût pour préfigurer un projet de
            recherche-action.
          </li>
        </ul>
        <figure>
          <img
            src="/images/temp/projets-explorer-02.webp"
            alt="projets-explorer-02"
          />
        </figure>
        <p>
          L’objectif de cette exploration scientifique est de mieux comprendre
          le lien entre la dimension sensorielle, cognitive et l’alimentation,
          dans le but d’<b>améliorer la nutrition de l’enfant</b>. Un projet de
          recherche-action avec des hypothèses à vérifier sur le terrain pourra
          alors se dessiner à la suite de cette étude.
        </p>
        <a href="https://www.isthia.fr" target='_blank' rel="noreferrer">
          <b>https://www.isthia.fr</b>
          <p>
            <img src={IMG_URL+"logo_isthia.webp"} alt="isthia jean jaures" />
          </p>
        </a>
      </div>,
  },
  {
    id: 1,
    title: "ACCOMPAGNER la neuroéducation en Afrique - Ana-Nour",
    text: "Le Fonds de Dotation Groupe Nutriset est partenaire de l’ONG française Ana-Nour, fondée et présidée par Mme Stéphanie Delgado, qui promeut les avantages de la neuro-éducation en Afrique notamment par la formation d'enseignants de classe maternelle.",
    imgURL: "/images/temp/projets-accompagner-01.webp",
    details: (
      <div>
        <p>
          Le soutien du Fonds de dotation Groupe Nutriset sur 3 ans (2022-2024) va en particulier :
        </p>
        <ul>
          <li>
            Contribuer à la mise en place du second colloque international sur
            les enjeux de la Neuroéducation en Afrique ;
          </li>
          <li>
            Participer à l’équipement des classes partenaires en matériel
            pédagogique cognitif, livres et « kits ressources » ;
          </li>
          <li>
            Permettre l’accès aux enseignants et chercheurs togolais au DU
            Neuroéducation de l’Université de Paris.
          </li>
          <b>Grâce à cette démarche, des centaines d'enfants et enseignants ouest-africains accèdent à une démarche pédagogique innovante à la croisée des neurosciences et des sciences de l’éducation</b>
        </ul>
        <figure>
          <img
            src="/images/temp/projets-accompagner-02.webp"
            alt="projets-explorer-02"
          />
        </figure>
        <a href="https://www.ana-nour.org" target="_blank" rel="noreferrer">
          <b>www.ana-nour.org</b>
          <p>
            <img
              src={process.env.REACT_APP_API_URL + "/img/logo-ana-nour.webp"}
              alt="ana-our"
            />
          </p>
        </a>
      </div>
    ),
  },
  {
    id: 2,
    title: "STIMULER la fonction cognitive - Ex-Core",
    text: "Le projet Ex-Core (Exercise Cognitive Rehabilitation) est une étude portée par le Pr Terrence Forrester de l’Université des Indes Occidentales.",
    imgURL: "/images/temp/projets-stimuler-01.webp",
    details: (
      <div>
        <ul>
          <li>
            La MAS (Malnutrition Aigüe Sévère) infantile a des effets
            dévastateurs sur le développement des capacités cognitives et
            affectives de l’enfant, persistant à l’âge adulte.
          </li>
          <li>
            Les premiers travaux scientifiques menés par notre partenaire de
            recherche montrent qu’une activité physique de type aérobique,
            régulière et modérée, permet de stimuler la fonction cognitive d’un
            individu adulte (30-50 ans).
          </li>
          <li>
            L’objectif de ce projet est d’adresser cette problématique via le
            test d’une activité physique aérobique d’intensité modérée ciblant
            les effets débilitants de la malnutrition chez ses survivants.
          </li>
          <li>
            Il est proposé d’examiner si l’intervention améliore globalement la
            cognition, avec un focus particulier sur les fonctions exécutives.
          </li>
          <b>
            Les résultats pourraient permettre un changement de trajectoire
            neurocognitive pour des centaines de millions d’adultes dans le
            monde.
          </b>
        </ul>
        <figure>
          <img
            src="/images/temp/projets-stimuler-02.webp"
            alt="projets-explorer-02"
          />
        </figure>
        <a href="https://www.uwi.edu/" target="_blank" rel="noreferrer">
          <b>https://www.uwi.edu/</b>
          <p><img src={IMG_URL+"university-west-indies.png"} alt="université de west indies" /></p>
        </a>
      </div>
    ),
  },
];

const ProjectlistEn = [
  {
    id: 0,
    title: "EXPLORE - Taste acquisition",
    text: "How are food preferences formed in our children? Is taste an innate sense or can it be developed and even educated? ",
    imgURL: "/images/temp/projets-explorer-01.webp",
    details:  <div>
      <p>
        In March 2022, a study was launched to investigate the connection between food and cognition, focusing particularly on the factors that influence taste acquisition.
        The subject, led by Aurore Pointet, a student at the University of Toulouse - Jean Jaurès studying food sociology, is a cross between neuroscience and taste education. The aim is to use cognition as a lever for improving the quality of food in children.
        <b>The exploratory research project is divided into 3 phases:</b>
      </p>
      <ul>
        <li>Literature review and inventory of research in neurosciences, nutrition and socio-anthropology of food concerning taste education.</li>
        <li>Screening of research actors in the field of cognition/nutrition in all disciplines: neurosciences, education sciences, food sciences, socio-anthropology of food, etc.</li>
        <li>Conducting interviews with key players in research and operations on taste education to prefigure a research-action project.</li>
      </ul>
      <figure>
        <img src="/images/temp/projets-explorer-02.webp" alt="projets-explorer-02" />
      </figure>
      <p>
        The objective of this scientific exploration is to better understand the link between the sensory and cognitive dimensions of food, with the aim of improving children's nutrition. A research-action project with hypotheses to be verified in the actual environment could then be developed as a result of this study.
      </p>
      <a href="https://www.isthia.fr" target='_blank' rel="noreferrer">
        <b>https://www.isthia.fr</b>
        <p>
          <img src={IMG_URL+"logo_isthia.webp"} alt="isthia jean jaures" />
        </p>
      </a>
    </div>
  },
  {
    id: 1,
    title: "SUPPORT neuro-education in Africa - Ana-Nour",
    text: "The Nutriset Group Endowment Fund is a partner of the French NGO Ana-Nour, founded and chaired by Ms. Stéphanie Delgado, which supports kindergarten education.",
    imgURL: "/images/temp/projets-accompagner-01.webp",
    details: <div>
      <p>
        The support of the Nutriset Group Endowment Fund over 3 years (2022-2024) will notably allow to:
      </p>
      <ul>
        <li>Contribute to the setting up of the second international symposium on the challenges of neuroeducation in Africa;</li>
        <li>Contribute to the equipment of partner classes with cognitive teaching materials, books and resource kits;</li>
        <li>Allow Togolese teachers and researchers to have access to the neuroeducation diploma of the University of Paris.</li>
      </ul>
      <figure>
        <img src="/images/temp/projets-accompagner-02.webp" alt="projets-explorer-02" />
      </figure>
      <a href="https://www.ana-nour.org" target="_blank" rel="noreferrer">
        <b>www.ana-nour.org</b>
        <p><img src={process.env.REACT_APP_API_URL+"/img/logo-ana-nour.webp"} alt="ana-nour" /></p>
      </a>
    </div>
  },
  {
    id: 2,
    title: "STIMULATE cognitive function - Ex-Core",
    text: "The Ex-Core (Exercise Cognitive Rehabilitation) project is a study led by Professor Terrence Forrester of the University of the West Indies, Mona Jamaica.",
    imgURL: "/images/temp/projets-stimuler-01.webp",
    details:
        <div>
          <ul>
            <li>Childhood SAM (Severe Acute Malnutrition) has devastating effects on the development of a child's cognitive and affective abilities, that persist into adulthood. </li>
            <li>Initial scientific studies carried out by our research partner show that regular, moderate aerobic physical activity can stimulate cognitive function in adults (aged 30-50).</li>
            <li>The objective of this project is to assess the debilitating effects of malnutrition in its survivors by testing a moderate intensity aerobic physical activity. </li>
            <li>It is proposed to examine whether the intervention improves overall cognition, with a particular focus on executive functions. </li>
            <b>The results could enable a change in the neurocognitive trajectory for hundreds of millions of adults worldwide.</b>
          </ul>
          <figure>
            <img src="/images/temp/projets-stimuler-02.webp" alt="projets-explorer-02" />
          </figure>
          <a href="/src/pages" onClick={(e) => e.preventDefault()}>
            <img src={IMG_URL+"university-west-indies.png"} alt="logo & lien en attente" />
          </a>
        </div>
  },
];

const ProjectElement = ({ value }) => {
  const [show, setShow] = useState(false);

  const handler = () => {
    setShow(!show);
  };

  return (
    <>
      <div onClick={handler} className='header'>
        <h2 className="h1">{value.title}</h2>
        <p className="puce_div">
          {!show ? (
            <img
              src={IMG_URL + "puce-genese.svg"}
              className={"puce__genese"}
              alt={"puce-genese" + value.id}
            />
          ) : (
            <img
              src={IMG_URL + "Bouton triangle vert.svg"}
              className={"puce__genese"}
              alt={"puce-genese" + value.id}
            />
          )}
        </p>
      </div>
      <div className="clearfix"></div>
      <p className="text">{value.text}</p>

      {show ? (
          <div className={'text ' + css(styles.tada)}>
            <div className="clearfix"></div>
            <figure>
              <img src={value.imgURL} alt="project_img" />
            </figure>
            {value.details}
          </div>
      ) : null}
    </>
  );
};

const ProjetAccordion = ({i18n}) => {
  return (
    <div className="project_list">
      {i18n.language === 'fr' || i18n.language ==='fr-FR' ? ProjectList.map((element, key) => {
        return (
          <div key={key}>
            <ProjectElement value={element} />
            {key !== ProjectList.length - 1 ? <hr /> : null}
          </div>
        );
      }): null}
      {i18n.language === 'en' ? ProjectlistEn.map((element, key) => {
        return (
          <div key={key}>
            <ProjectElement value={element} />
            {key !== ProjectList.length - 1 ? <hr /> : null}
          </div>
        );
      }): null}
    </div>
  );
};

export const Project = () => {
  const {t, i18n} = useTranslation();
  return (
    <section className="project">
      <div className="clearfix"></div>
      <p className="section_image">
        <img src={IMG_URL + "Cercle vert.png"} alt="cercle-vert-coupe" />
      </p>
      <Container>
        <div className="clearfix"></div>
        <Title
          id="project"
          value={t('project.title')}
        />
        <div className="clearfix"></div>
        <ProjetAccordion i18n={i18n} />
      </Container>
    </section>
  );
};
